import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AffiliateImages = () => {
  const [affiliateImages, setAffiliateImages] = useState([]);
  const [randomImage, setRandomImage] = useState(null);
  const [randomTagLine, setRandomTagLine] = useState(''); // State for the selected tag line
  const [error, setError] = useState(null);

  // List of tag lines to encourage user interaction
  const tagLines = [
    "CHECK IT OUT!",
    "LIMITED TIME!",
    "GRAB IT FAST!",
    "EXCITING DEAL!",
    "DON'T MISS OUT!",
    "ACT NOW!",
    "TOP PICK!",
    "BEST CHOICE!",
    "SEE MORE!",
    "TRENDING NOW!",
    "MUST-HAVE!",
    "HOT ITEM!",
    "NEW ARRIVAL!",
    "JUST IN!",
    "HURRY UP!",
    "COOL PICK!",
    "LATEST FIND!",
    "CHECK THIS!",
    "AMAZING!",
    "SPECIAL PICK!"
  ];

  const fetchAffiliateImages = async () => {
    try {
      const response = await axios.get('/api/v1/affiliate_images');
      setAffiliateImages(response.data);
    } catch (err) {
      console.error('Error fetching affiliate images:', err);
      setError('Failed to load affiliate images.');
    }
  };

  useEffect(() => {
    fetchAffiliateImages();

    // Set a random tag line once when the component is initialized
    const randomIndex = Math.floor(Math.random() * tagLines.length);
    setRandomTagLine(tagLines[randomIndex]);
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    if (affiliateImages.length > 0) {
      const randomIndex = Math.floor(Math.random() * affiliateImages.length);
      setRandomImage(affiliateImages[randomIndex]);
    }
  }, [affiliateImages]);

  if (error) return <p>{error}</p>;
  if (!randomImage) return null; // Avoid rendering "Loading..." text

  return (
    <div style={{ position: 'relative', textAlign: 'center', margin: '20px 0' }}>
      <a href={randomImage.link} target="_blank" rel="noopener noreferrer">
        <img
          src={randomImage.image_url}
          alt="Affiliate"
          style={{
            maxWidth: '100%',
            width: 'auto',
            height: 'auto',
            borderRadius: '8px',
            margin: '10px auto',
            display: 'block',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        />
        {/* Banner overlay */}
        <div style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          backgroundColor: 'rgba(255, 0, 0, 0.7)', // Translucent red
          color: 'white',
          fontWeight: 'bold',
          padding: '5px 15px',
          transform: 'rotate(-45deg)', // Rotates the banner
          zIndex: 1,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        }}>
          {randomTagLine}
        </div>
      </a>
      <p style={{ fontWeight: 'bold', marginTop: '10px' }}>{randomImage.caption}</p>
    </div>
  );
};

export default AffiliateImages;

