// App.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Slate, Editable, withReact } from 'slate-react';
import { createEditor, Descendant } from 'slate';
import AffiliateImages from './components/AffiliateImages';

axios.defaults.baseURL = '/';
const baseURL = 'http://aimnewsbreak.com';

const App = () => {
  const [entries, setEntries] = useState([]);
  const [editors, setEditors] = useState([]); // State to hold editor instances
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageWidths, setImageWidths] = useState({}); // Store image widths by entry ID
  const [showUpArrow, setShowUpArrow] = useState(false); // State to manage up arrow visibility
  const [showDownArrow, setShowDownArrow] = useState(false); // State to manage down arrow visibility
  const [J, setJ] = useState(false); // Initialize J as a state variable
  const imageRefs = useRef({}); // Store refs for each image
  const previousWidths = useRef({}); // Store the previous widths to avoid unnecessary updates
  const containerRef = useRef(null); // Ref for the scrollable container
  const bottomLineRef = useRef(null); // Ref for the bottom red line
  const MAX_RECORDS = 100; // Maximum number of records to display
  const SHOW_VIDEO = false;

  const affiliateLinks = [
    "https://amzn.to/3SWVNhX",
    "https://amzn.to/4cDzrZP",
    "https://amzn.to/3YTUDrf",
    "https://amzn.to/4cAgPK7",
    "https://amzn.to/46V7NWU",
    "https://amzn.to/4fXPx3t",
    "https://amzn.to/4fUaPPi",
    "https://amzn.to/3Xd5jQn",
    "https://amzn.to/46UBjMm",
    "https://amzn.to/4dCWlSA",
    "https://amzn.to/3WXaWAP"
  ];

  const getRandomAffiliateLink = () => {
    const randomIndex = Math.floor(Math.random() * affiliateLinks.length);
    return affiliateLinks[randomIndex];
  };

  const logToServer = async (message) => {
    try {
      await fetch('http://aimnewsbreak.com/log', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });
    } catch (err) {
      console.error('Failed to log to server:', err.message);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      console.log("FETCH");
      const response = await axios.get('/api/v1/blog_entries', {
        params: {
          limit: 10, // Adjust as per your Rails API implementation
          sort: "desc",
          created_before: new Date().toISOString(), // Sends current date and time
        },
      });
      const data = response.data;

      const processedEntries = data.map(entry => {
        const id = entry.id;
        const headline = entry.headline || '';
        const storyJson = entry.story;
        const story = storyJson ? JSON.parse(storyJson) : [];
        const createdAt = entry.created_at;
        const topImageId = entry.top_image_id;
        const video1 = entry.video_1;
        const topImageUrl = topImageId ? `/api/v1/images/${topImageId}/download` : null;

        return {
          id,
          headline,
          story,
          createdAt,
          topImage: topImageUrl,
          video1,
        };
      });

      processedEntries.forEach(entry => {
        logToServer(`Entry ID: ${entry.id}, Top Image URL: ${entry.topImage}`);
      });

      const limitedEntries = processedEntries.slice(0, MAX_RECORDS);

      if (JSON.stringify(entries) !== JSON.stringify(limitedEntries)) {
        setEntries(limitedEntries);
        setEditors(limitedEntries.map(() => withReact(createEditor())));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [entries]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 10000);
    return () => clearInterval(intervalId);
  }, [fetchData]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const previousScrollTop = useRef(0); // Store the previous scroll position
  const handleScroll = () => {
    const container = containerRef.current;
    const bottomLine = bottomLineRef.current;
    if (container && bottomLine) {
      const { scrollTop } = container;
      const isScrollingUp = scrollTop < previousScrollTop.current;

      if (isScrollingUp && scrollTop <= 5) {
        flashArrow('up');
      }
      // Update the previous scroll position
      previousScrollTop.current = scrollTop;

      const bottomLineRect = bottomLine.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      if (bottomLineRect.top <= containerRect.bottom && bottomLineRect.top >= containerRect.top) {
        flashArrow('down');
      }
    }
  };

  const flashArrow = (direction) => {
    if (direction === 'up') {
      setShowUpArrow(true);
      setTimeout(() => setShowUpArrow(false), 1000); // Hide after 1 second
    } else if (direction === 'down') {
      setShowDownArrow(true);
      setTimeout(() => setShowDownArrow(false), 1000); // Hide after 1 second
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const convertChildToSlate = (child, video1) => {
    if (typeof child.text === 'string') {
      if (child.text.includes('{{VIDEO_1}}')) {
        if (SHOW_VIDEO && video1) {
          const videoId = video1.split('v=')[1];
          const embedUrl = `https://www.youtube.com/embed/${videoId}`;

          return {
            type: 'youtube-video',
            url: embedUrl,
            children: [{ text: '' }],
          };
        } else {
          // Remove the placeholder from the text
          child.text = child.text.replace('{{VIDEO_1}}', '');
          if (child.text.trim() === '') {
            return null; // Remove the node if there's no other text
          }
          // Continue processing the text node
        }
      }

      let textNode = { text: child.text };

      if (child.type === 'text') {
        console.log('Processing text node:', JSON.stringify(child));
        if (child.italic) {
          textNode.italic = true;
        }
        if (child.bold) {
          textNode.bold = true;
        }
        if (textNode.bold || textNode.italic) {
          console.log('Enhanced text node:', JSON.stringify(textNode));
        }
      }
      return textNode;
    } else if (child.type === 'link') {
      return {
        type: 'link',
        url: child.url,
        children: child.children.map(linkChild => convertChildToSlate(linkChild, video1)).filter(Boolean),
      };
    } else {
      return { text: 'Unsupported child type' };
    }
  };

  const convertToSlateStructure = (story, video1) => {
    const parseList = (items) => {
      return items.map(item => ({
        type: 'list-item',
        children: item.children.map(child => convertChildToSlate(child, video1)).filter(Boolean),
      }));
    };

    return story.map(block => {
      const children = block.children.map(child => convertChildToSlate(child, video1)).filter(Boolean);

      if (children.length === 0) {
        return null;
      }

      switch (block.type) {
        case 'paragraph':
          return {
            type: 'paragraph',
            children,
          };
        case 'heading':
          return {
            type: 'heading',
            level: block.level || 1,
            children,
          };
        case 'list':
          return {
            type: 'bulleted-list',
            children: parseList(block.children),
          };
        case 'image':
          return {
            type: 'image',
            url: block.url,
            alt: block.alt || '',
            children: [{ text: '' }],
          };

        case 'youtube-video':
          if (SHOW_VIDEO) {
            return {
              type: 'youtube-video',
              url: block.url,
              children: [{ text: '' }],
            };
          } else {
            return null;
          }

        default:
          return {
            type: 'paragraph',
            children: [{ text: 'Unsupported block type' }],
          };
      }
    }).filter(Boolean);
  };

  const renderElement = ({ attributes, children, element }) => {
    switch (element.type) {
      case 'heading':
        const Tag = `h${element.level}`;
        return <Tag {...attributes} style={{ marginTop: 0 }}>{children}</Tag>;
      case 'bulleted-list':
        return <ul {...attributes} style={{ marginTop: 0 }}>{children}</ul>;
      case 'list-item':
        return <li {...attributes} style={{ marginTop: 0 }}>{children}</li>;
      case 'link':
        return (
          <a {...attributes} href={element.url} target="_blank" rel="noopener noreferrer" style={{ marginTop: 0 }}>
            {children}
          </a>
        );
      case 'image':
        console.log(`Rendering image with URL: ${element.url}, ID: ${element.id}`);
        return (
          <div {...attributes} style={{ textAlign: J ? 'center' : 'right', margin: '0 auto' }}>
            <img
              ref={(el) => {
                if (el && !imageRefs.current[element.id]) {
                  console.log(`Requesting image from URL: ${element.url}, ID: ${element.id}`);

                  el.onload = () => {
                    console.log(`Image loaded successfully, ID: ${element.id}`);
                  };

                  el.onerror = () => {
                    console.log(`Image failed to load, ID: ${element.id}`);
                  };
                }

                imageRefs.current[element.id] = el;

                if (el && el.offsetWidth !== previousWidths.current[element.id]) {
                  previousWidths.current[element.id] = el.offsetWidth;
                  setImageWidths((prevWidths) => ({
                    ...prevWidths,
                    [element.id]: el.naturalWidth,
                  }));
                }
              }}
              src={element.url ? `${baseURL}${element.url}` : null}
              alt={element.alt}
              style={{
                width: `${imageWidths[element.id] || 'auto'}px`,
                height: 'auto',
                maxHeight: '400px',
                objectFit: 'contain',
                float: J ? 'none' : 'right',
                marginLeft: J ? 'auto' : '20px',
                marginRight: J ? 'auto' : '0',
                display: 'block',
              }}
            />
            {children}
          </div>
        );
      case 'youtube-video':
        const maxVideoWidth = Math.min(560, windowWidth - 40);
        const videoAspectRatio = 315 / 560;
        const videoHeight = maxVideoWidth * videoAspectRatio;

        return (
          <div {...attributes} style={{ margin: '20px 0', textAlign: 'center' }}>
            <iframe
              width={maxVideoWidth}
              height={videoHeight}
              src={element.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {children}
          </div>
        );
      case 'paragraph':
        return <p {...attributes} style={{ marginTop: 0 }}>{children}</p>;
      case 'text':
        console.log('Rendering text node:', JSON.stringify(element));
        return (
          <span
            {...attributes}
            style={{
              fontStyle: element.italic ? 'italic' : 'normal',
              fontWeight: element.bold ? 'bold' : 'normal',
            }}
          >
            {children}
          </span>
        );
      default:
        return <p {...attributes} style={{ marginTop: 0 }}>{children}</p>;
    }
  };

  return (
    <div ref={containerRef} style={{ position: 'relative', overflowY: 'scroll', height: '100vh' }}>
      {showUpArrow && (
        <div style={{
          position: 'fixed',
          top: '10%',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: '72px',
          color: 'white',
          backgroundColor: 'black',
          padding: '10px',
          borderRadius: '50%',
          zIndex: 1000
        }}>
          &#8679;
        </div>
      )}
      {showDownArrow && (
        <div style={{
          position: 'fixed',
          bottom: '10%',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: '72px',
          color: 'white',
          backgroundColor: 'black',
          padding: '10px',
          borderRadius: '50%',
          zIndex: 1000
        }}>
          &#8681;
        </div>
      )}

      <ul>
        {entries.map((entry, index) => {
          const initialValue = [
            ...convertToSlateStructure(entry.story, entry.video1),
          ];

          // Determine if the screen is narrow
          const isNarrow = windowWidth < 600; // Adjust breakpoint as needed

          // Adjust styles based on screen width
          const affiliateImageStyle = {
            width: isNarrow ? '100%' : '30%',
            float: isNarrow ? 'none' : 'right',
            marginLeft: isNarrow ? '0' : '15px',
            marginBottom: isNarrow ? '15px' : '0',
            boxSizing: 'border-box',
          };

          return (
            <li
              id={`entry-${entry.id}`}
              key={entry.id || index}
              style={{
                marginBottom: '20px',
                padding: '10px',
                boxSizing: 'border-box',
                // Apply clearfix using :after pseudo-element
                position: 'relative',
              }}
            >
              {/* Affiliate Images */}
              <div style={affiliateImageStyle}>
                <AffiliateImages />
              </div>

              {/* Text Content */}
              <div>
                <Slate editor={editors[index]} initialValue={initialValue}>
                  <Editable
                    readOnly
                    style={{
                      fontSize: '16px',
                      lineHeight: '1.5',
                      marginTop: 0,
                      paddingTop: 0,
                    }}
                    renderElement={renderElement}
                  />
                </Slate>
              </div>

              {/* Clearfix */}
              <div style={{ clear: 'both' }}></div>

              {index < entries.length - 1 && (
                <div style={{ borderTop: '5px solid red', marginTop: '20px', clear: 'both' }}></div>
              )}
            </li>
          );
        })}
      </ul>

      <div ref={bottomLineRef} style={{ borderTop: '5px solid red', marginTop: '20px' }}></div>
    </div>
  );
};

export default App;

